import { isRouteErrorResponse, useRouteError } from '@remix-run/react'
import { css } from '#styled-system/css/css.js'

export const ErrorBoundary = () => {
	const error = useRouteError()

	if (process.env.NODE_ENV === 'development') {
		console.error(error)
	}

	if (isRouteErrorResponse(error)) {
		return (
			<div
				className={css({
					padding: '64px',
				})}
			>
				<p
					className={css({
						fontSize: '14px',
						lineHeight: '20px',
						fontWeight: '600',
						textTransform: 'uppercase',
						color: 'gray11',
					})}
				>
					Error: {error.status}
				</p>
				<h1
					className={css({
						marginTop: '8px',
						fontSize: '36px',
						lineHeight: '40px',
						fontWeight: '800',
						letterSpacing: '-0.025em',
						color: 'gray12',
						'@media(min-width:640px)': {
							fontSize: '48px',
							lineHeight: '1',
						},
					})}
				>
					{error.status === 404 ? 'Page not found' : 'Something went wrong'}
				</h1>
				<pre
					className={css({
						marginTop: '8px',
					})}
				>
					<code>{JSON.stringify(error.data, null, 2)}</code>
				</pre>
				<div
					className={css({
						marginTop: '24px',
					})}
				>
					<a
						className={css({
							fontWeight: '500',
							color: 'gray11',
							textDecoration: 'underline',
							textUnderlineOffset: '1px',
							'&:hover': {
								color: 'gray12',
							},
						})}
						// eslint-disable-next-line remix-react-routes/use-link-for-routes
						href="mailto:contact@notionicons.so"
					>
						contact@notionicons.so
					</a>
				</div>
			</div>
		)
	}

	// Don't forget to typecheck with your own logic.
	// Any value can be thrown, not just errors!
	//   let errorMessage = "Unknown error";
	//   if (isDefinitelyAnError(error)) {
	//     errorMessage = error.message;
	//   }

	return (
		<div
			className={css({
				padding: '64px',
			})}
		>
			<p
				className={css({
					fontSize: '14px',
					lineHeight: '20px',
					fontWeight: '600',
					textTransform: 'uppercase',
					color: 'gray11',
				})}
			>
				Error
			</p>
			<h1
				className={css({
					marginTop: '8px',
					fontSize: '36px',
					lineHeight: '40px',
					fontWeight: '800',
					letterSpacing: '-0.025em',
					color: 'gray12',
					'@media(min-width:640px)': {
						fontSize: '48px',
						lineHeight: '1',
					},
				})}
			>
				Something went wrong
			</h1>
			<p
				className={css({
					marginTop: '8px',
				})}
			>
				Please, report the error to us via the email below.
			</p>
			<div
				className={css({
					marginTop: '24px',
				})}
			>
				<a
					className={css({
						fontWeight: '500',
						color: 'gray11',
						textDecoration: 'underline',
						textUnderlineOffset: '1px',
						'&:hover': {
							color: 'gray12',
						},
					})}
					// eslint-disable-next-line remix-react-routes/use-link-for-routes
					href="mailto:contact@notionicons.so"
				>
					contact@notionicons.so
				</a>
			</div>
		</div>
	)
}
